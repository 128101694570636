import { Svg } from "atoms/SvgIcon";

const IconHeartProblems = ({ ...rest }) => {
    return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <path d="M9.48259 9.9306C10.0318 9.98293 10.5717 10.1078 11.0881 10.302C11.5697 8.41393 11.7813 6.46716 11.7166 4.51973V3.94836C10.9895 4.2357 10.213 4.37741 9.43116 4.36546C8.65351 4.37873 7.88084 4.23895 7.15711 3.95408C7.16854 4.50259 7.16854 4.98254 7.14569 5.38822C7.08855 6.20527 6.82001 8.21078 5.48872 9.11926C4.44484 9.80802 3.70937 10.8754 3.4375 12.0961C4.20554 11.3038 5.15169 10.7063 6.19721 10.3534C7.25035 9.98273 8.36991 9.83864 9.48259 9.9306Z" fill="#4B4B63" />
        <path d="M33.6742 24.5348L27.6749 14.9416C27.3201 14.3561 26.6853 13.9985 26.0007 13.9985C25.3162 13.9985 24.6814 14.3561 24.3266 14.9416L18.3273 24.5348C17.9124 25.1832 17.8904 26.0079 18.2701 26.6775C18.6131 27.3144 19.278 27.7116 20.0014 27.7117H32.0001C32.7235 27.7116 33.3884 27.3144 33.7314 26.6775C34.1111 26.0079 34.0891 25.1832 33.6742 24.5348ZM26.5721 25.4262C26.5721 25.7417 26.3163 25.9976 26.0007 25.9976C25.6852 25.9976 25.4294 25.7417 25.4294 25.4262V24.2834C25.4294 23.9679 25.6852 23.7121 26.0007 23.7121C26.3163 23.7121 26.5721 23.9679 26.5721 24.2834V25.4262ZM26.5721 21.998C26.5721 22.3135 26.3163 22.5693 26.0007 22.5693C25.6852 22.5693 25.4294 22.3135 25.4294 21.998V16.8556C25.4294 16.5401 25.6852 16.2843 26.0007 16.2843C26.3163 16.2843 26.5721 16.5401 26.5721 16.8556V21.998Z" fill="#4B4B63" />
        <path d="M17.2699 27.2317C16.6966 26.197 16.7294 24.9328 17.3556 23.9292L22.1951 16.1871C21.1609 13.5645 19.5325 11.9647 17.9727 12.039C17.7931 12.1447 17.6295 12.2755 17.487 12.4275C17.3775 12.5657 17.2119 12.6475 17.0356 12.6503C16.9092 12.6508 16.7864 12.6085 16.6871 12.5304C16.4418 12.3418 16.3911 11.9923 16.5728 11.7419C16.8348 11.4283 17.1549 11.1684 17.5156 10.9762C18.4697 10.422 20.121 9.94776 22.7207 10.5077C22.6656 9.96476 22.74 9.41647 22.9379 8.90787C23.1125 8.40579 23.3998 7.95039 23.7778 7.57658C23.6006 7.50802 23.4235 7.43945 23.2464 7.38231C17.5213 5.50251 13.7388 9.07928 12.2704 10.8791C15.2415 12.7189 16.35 16.4899 16.3957 16.6614C16.4638 16.9575 16.2883 17.2551 15.9962 17.3388C15.7041 17.4225 15.3976 17.2629 15.2986 16.9756C15.2872 16.9356 14.1273 13.0103 11.1619 11.5705C10.6005 11.2987 9.99469 11.1303 9.37354 11.0734C8.42114 10.9932 7.46262 11.1179 6.56241 11.439C5.16144 11.9247 3.98669 12.9063 3.25989 14.1988C3.23755 14.29 3.19004 14.3732 3.12276 14.4387C2.47962 15.6734 2.10756 17.0311 2.03145 18.4212C1.7357 24.1951 4.95955 29.5749 10.1906 32.0369C13.9902 33.9338 16.7899 34.4309 18.7383 33.5453C20.201 32.8825 21.2523 31.3741 22.0122 28.8544H20.001C18.861 28.8587 17.8112 28.235 17.2699 27.2317ZM7.28233 13.6062C5.60251 14.1702 4.49176 16.0271 4.31121 18.5812C4.28989 18.8808 4.04021 19.1127 3.73984 19.112H3.69928C3.54801 19.1013 3.4072 19.0309 3.30785 18.9163C3.20851 18.8018 3.15878 18.6524 3.16962 18.5012C3.38388 15.4729 4.78545 13.236 6.91837 12.5235C7.21815 12.4236 7.54214 12.5857 7.64201 12.8855C7.74188 13.1852 7.57983 13.5092 7.28005 13.6091L7.28233 13.6062Z" fill="#4B4B63" />
        <path d="M9.43001 3.22273C10.807 3.22273 11.6012 2.7942 11.7098 2.61137C11.6012 2.42853 10.807 2 9.43001 2C8.01301 2 7.2131 2.45138 7.14453 2.62851V2.63422C7.2131 2.77135 8.01301 3.22273 9.43001 3.22273Z" fill="#4B4B63" />
        <path d="M24.0806 10.8792C24.2863 10.9535 24.8463 10.5707 25.1548 9.72502C25.3033 9.35561 25.3544 8.95427 25.3034 8.55943C25.2634 8.34231 25.1777 8.18233 25.0862 8.14804C25.0676 8.14302 25.0483 8.14109 25.0291 8.14233C24.7948 8.14233 24.292 8.52515 24.0063 9.30221C23.6978 10.1478 23.8749 10.8049 24.0806 10.8792Z" fill="#4B4B63" />
    </Svg>
}

export default IconHeartProblems;

