import React from 'react'
import Text from 'atoms/Text'
import ImageNext from 'atoms/ImageNext'

const ImageCaptionCardV2 = ({ className = '', src, alt, caption, objectPosition = 'top', onClick  }) => {
    return (
        <figure onClick={onClick} className={`${className} ${onClick ? 'cursor-pointer' : ''}`}>
            <div className='relative aspect-[300/250] rounded-lg overflow-hidden'>
                <ImageNext src={src} alt={alt} layout='fill' objectFit='cover' objectPosition={objectPosition} />
            </div>

            <Text tag='figcaption' className='font-Open-Sans text-center text-14-20 mt-3 text-gray-900 ' fontWeight={400}>
                {caption}
            </Text>
        </figure>
    )
}

export default ImageCaptionCardV2