import { Svg } from "atoms/SvgIcon";

const IconCosmeticTreatment = ({ ...rest }) => {
    return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <path d="M23.9114 11.5786C23.0923 10.6514 22.0723 9.91728 20.9441 9.34546C20.9827 9.0441 21.0059 8.735 21.0059 8.43364C21.0059 4.33046 17.575 1 13.3636 1C9.15228 1 5.72137 4.33046 5.72137 8.43364C5.72137 8.735 5.74455 9.0441 5.78319 9.34546C4.655 9.91728 3.635 10.6514 2.81591 11.5786C1.34773 13.2555 0.768185 15.3109 1.085 17.6832C1.29364 19.2905 1.78819 20.6273 1.96591 21.0677C2.02 27.2418 5.56682 34.1887 13.2864 34.9923C13.3096 35 13.3405 35 13.3636 35C13.3946 35 13.4177 35 13.4409 34.9923C14.4918 34.8841 15.4886 34.66 16.4159 34.3355C16.8177 34.1887 17.0341 33.7482 16.8873 33.3464C16.7405 32.9446 16.3 32.7359 15.8982 32.875C15.11 33.1609 14.26 33.3541 13.3636 33.4468C5.8141 32.6123 3.64273 25.6114 3.5191 21.2996C3.52682 21.2996 3.53455 21.2841 3.54228 21.2764C3.64273 21.0909 6.08455 16.6941 13.3636 16.6941C20.6427 16.6941 23.0846 21.0832 23.185 21.2764C23.185 21.2841 23.2005 21.2918 23.2082 21.2996C23.185 21.9409 23.1387 22.59 23.0305 23.2468C22.9687 23.6718 23.2546 24.0659 23.6718 24.1355C24.0968 24.205 24.4909 23.9114 24.5605 23.4941C24.6918 22.675 24.7537 21.8636 24.7614 21.0677C24.9391 20.6273 25.4337 19.2905 25.6423 17.6832C25.9591 15.3109 25.3796 13.2555 23.9114 11.5786ZM13.3636 7.66091C11.5941 7.66091 9.52319 7.93137 7.58364 8.58818C7.77683 7.63773 9.94046 6.40909 13.3636 6.40909C16.7868 6.40909 18.9505 7.63773 19.1436 8.58818C17.2041 7.93137 15.1332 7.66091 13.3636 7.66091Z" fill="#4B4B63" />
        <path d="M8.30193 23.3241C9.05147 22.9455 9.94784 22.9455 10.7051 23.3241C10.8133 23.3782 10.9369 23.4091 11.0528 23.4091C11.3387 23.4091 11.6092 23.2546 11.7483 22.9841C11.9415 22.6055 11.7869 22.1419 11.4006 21.9487C10.2183 21.3537 8.8042 21.3537 7.62193 21.9487C7.24329 22.1419 7.08875 22.6055 7.2742 22.9841C7.46738 23.3628 7.93102 23.5173 8.30966 23.3319L8.30193 23.3241Z" fill="#4B4B63" />
        <path d="M16.0295 23.3241C16.779 22.9455 17.6754 22.9455 18.4326 23.3241C18.5408 23.3782 18.6645 23.4091 18.7804 23.4091C19.0663 23.4091 19.3367 23.2546 19.4758 22.9841C19.669 22.6055 19.5145 22.1419 19.1281 21.9487C17.9458 21.3537 16.5317 21.3537 15.3495 21.9487C14.9708 22.1419 14.8163 22.6055 15.0017 22.9841C15.1949 23.3628 15.6586 23.5173 16.0372 23.3319L16.0295 23.3241Z" fill="#4B4B63" />
        <path d="M11.4705 31.0512C12.0655 31.3448 12.7146 31.4994 13.3637 31.4994C14.0128 31.4994 14.6619 31.3526 15.2569 31.0512C15.6355 30.858 15.7901 30.3944 15.6046 30.0157C15.4192 29.6371 14.9478 29.4826 14.5692 29.668C13.8196 30.0466 12.9233 30.0466 12.166 29.668C11.7873 29.4748 11.3237 29.6294 11.1305 30.0157C10.9373 30.4021 11.0919 30.858 11.4783 31.0512H11.4705Z" fill="#4B4B63" />
        <path d="M7.95437 20.3182H11.0453C11.4703 20.3182 11.818 19.9704 11.818 19.5454C11.818 19.1204 11.4703 18.7727 11.0453 18.7727H7.95437C7.52937 18.7727 7.18164 19.1204 7.18164 19.5454C7.18164 19.9704 7.52937 20.3182 7.95437 20.3182Z" fill="#4B4B63" />
        <path d="M18.7728 18.7727H15.6819C15.2569 18.7727 14.9092 19.1204 14.9092 19.5454C14.9092 19.9704 15.2569 20.3182 15.6819 20.3182H18.7728C19.1978 20.3182 19.5455 19.9704 19.5455 19.5454C19.5455 19.1204 19.1978 18.7727 18.7728 18.7727Z" fill="#4B4B63" />
        <path d="M32.6818 23.4091H30.9972C30.6727 22.5127 29.8227 21.8636 28.8181 21.8636H27.2727C26.8477 21.8636 26.5 22.2114 26.5 22.6364V24.9546H20.3181C19.8931 24.9546 19.5454 25.3023 19.5454 25.7273C19.5454 26.1523 19.8931 26.5 20.3181 26.5H26.5V28.8182C26.5 29.2432 26.8477 29.5909 27.2727 29.5909H28.8181C29.8227 29.5909 30.6727 28.9418 30.9972 28.0455H32.6818C33.9568 28.0455 35 27.0023 35 25.7273C35 24.4523 33.9568 23.4091 32.6818 23.4091ZM29.5909 27.2727C29.5909 27.6977 29.2431 28.0455 28.8181 28.0455H28.0454V23.4091H28.8181C29.2431 23.4091 29.5909 23.7568 29.5909 24.1818V27.2727Z" fill="#4B4B63" />
        <path d="M22.6361 31.6539C22.6361 33.068 21.423 34.2271 19.9316 34.2271C18.4402 34.2271 17.2271 33.068 17.2271 31.6539C17.2271 30.4948 18.8189 28.2462 19.3134 27.5894C19.5993 27.1953 20.2639 27.1953 20.5498 27.5894C21.0443 28.2462 22.6361 30.4948 22.6361 31.6539Z" fill="#4B4B63" />
        <path d="M31.1365 14.1364C31.5615 14.1364 31.9092 13.7887 31.9092 13.3637V12.591C31.9092 12.166 31.5615 11.8182 31.1365 11.8182C30.7115 11.8182 30.3638 12.166 30.3638 12.591V13.3637C30.3638 13.7887 30.7115 14.1364 31.1365 14.1364Z" fill="#4B4B63" />
        <path d="M31.1365 15.6819C30.7115 15.6819 30.3638 16.0296 30.3638 16.4546V17.2273C30.3638 17.6523 30.7115 18.0001 31.1365 18.0001C31.5615 18.0001 31.9092 17.6523 31.9092 17.2273V16.4546C31.9092 16.0296 31.5615 15.6819 31.1365 15.6819Z" fill="#4B4B63" />
        <path d="M28.8181 14.1364C28.3931 14.1364 28.0454 14.4841 28.0454 14.9091C28.0454 15.3341 28.3931 15.6818 28.8181 15.6818H29.5909C30.0159 15.6818 30.3636 15.3341 30.3636 14.9091C30.3636 14.4841 30.0159 14.1364 29.5909 14.1364H28.8181Z" fill="#4B4B63" />
        <path d="M32.6819 15.6818H33.4546C33.8796 15.6818 34.2274 15.3341 34.2274 14.9091C34.2274 14.4841 33.8796 14.1364 33.4546 14.1364H32.6819C32.2569 14.1364 31.9092 14.4841 31.9092 14.9091C31.9092 15.3341 32.2569 15.6818 32.6819 15.6818Z" fill="#4B4B63" />
        <path d="M26.4998 4.09089C26.9248 4.09089 27.2725 3.74316 27.2725 3.31816V2.54543C27.2725 2.12043 26.9248 1.77271 26.4998 1.77271C26.0748 1.77271 25.7271 2.12043 25.7271 2.54543V3.31816C25.7271 3.74316 26.0748 4.09089 26.4998 4.09089Z" fill="#4B4B63" />
        <path d="M26.4998 5.63635C26.0748 5.63635 25.7271 5.98408 25.7271 6.40908V7.18181C25.7271 7.60681 26.0748 7.95454 26.4998 7.95454C26.9248 7.95454 27.2725 7.60681 27.2725 7.18181V6.40908C27.2725 5.98408 26.9248 5.63635 26.4998 5.63635Z" fill="#4B4B63" />
        <path d="M24.1819 5.6364H24.9546C25.3796 5.6364 25.7274 5.28867 25.7274 4.86367C25.7274 4.43867 25.3796 4.09094 24.9546 4.09094H24.1819C23.7569 4.09094 23.4092 4.43867 23.4092 4.86367C23.4092 5.28867 23.7569 5.6364 24.1819 5.6364Z" fill="#4B4B63" />
        <path d="M28.0457 5.6364H28.8184C29.2434 5.6364 29.5911 5.28867 29.5911 4.86367C29.5911 4.43867 29.2434 4.09094 28.8184 4.09094H28.0457C27.6207 4.09094 27.2729 4.43867 27.2729 4.86367C27.2729 5.28867 27.6207 5.6364 28.0457 5.6364Z" fill="#4B4B63" />
    </Svg>
}



export default IconCosmeticTreatment;