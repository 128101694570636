import { Svg } from "atoms/SvgIcon";

const IconThyroid = ({ ...rest }) => {

   return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <path d="M6.72147 2.66091C6.69501 2.49479 6.74313 2.32548 6.85291 2.19806C6.96077 2.07107 7.11941 1.99841 7.28605 1.99977H28.708C28.8747 1.99841 29.0333 2.07107 29.1412 2.19806C29.251 2.32548 29.299 2.49479 29.2726 2.66091C28.9686 4.64949 27.9618 9.42843 25.1703 10.8221C21.0782 12.8685 19.536 13.4284 17.997 13.4284C16.4581 13.4284 14.9159 12.8684 10.8238 10.8221C8.03234 9.42843 7.02547 4.64954 6.72147 2.66091Z" fill="#4B4B63" />
        <path d="M16.8535 27.7144H19.1392V28.8572H16.8535V27.7144Z" fill="#4B4B63" />
        <path d="M16.8535 30.0002H19.1392V31.1431H16.8535V30.0002Z" fill="#4B4B63" />
        <path d="M16.8535 26.5714V25.7388C17.0463 25.5002 17.2209 25.2475 17.3758 24.9828C17.6415 24.5491 17.8158 24.2856 17.9964 24.2856C18.177 24.2856 18.3513 24.5491 18.617 24.9828C18.772 25.2475 18.9465 25.5002 19.1393 25.7388V26.5714H16.8535Z" fill="#4B4B63" />
        <path d="M16.8535 32.8572V32.2858H19.1392V32.8572C19.1392 33.4884 18.6275 34.0001 17.9964 34.0001C17.3652 34.0001 16.8535 33.4884 16.8535 32.8572Z" fill="#4B4B63" />
        <path d="M19.5966 24.3864C19.2583 23.8327 18.8349 23.143 18.0012 23.143C17.1674 23.143 16.744 23.8327 16.4057 24.3864C16.0103 25.0327 15.7377 25.4287 15.144 25.4287C13.9742 25.4287 13.4297 23.0418 13.4297 21.4286C13.4297 19.5823 14.0583 18 14.5725 18C14.9074 18 15.448 18.5411 15.8828 18.9754C16.5269 19.6195 17.1931 20.2857 18.0011 20.2857C18.8091 20.2857 19.4755 19.6194 20.1194 18.9754C20.5543 18.5411 21.0949 18 21.4297 18C21.944 18 22.5726 19.5823 22.5726 21.4286C22.5726 23.0418 22.028 25.4287 20.8583 25.4287C20.2646 25.4288 19.9921 25.0328 19.5966 24.3864Z" fill="#4B4B63" />
        <path d="M19.1624 18.3132C18.7578 18.7138 18.2967 19.1429 17.9984 19.1429C17.7002 19.1429 17.239 18.7137 16.8344 18.3132C16.7875 16.9946 16.6406 15.6815 16.395 14.3851C17.4495 14.6335 18.5474 14.6335 19.6019 14.3851C19.3563 15.6815 19.2093 16.9946 19.1624 18.3132Z" fill="#4B4B63" />
        <path d="M1.99906 33.4287V25.7715C1.99785 25.6605 2.02945 25.5515 2.08991 25.4584C3.53394 23.2944 4.95167 22.9715 6.45282 22.6286C7.94005 22.4129 9.32195 21.7352 10.4031 20.6915C12.4083 18.6863 12.6209 15.2714 12.4803 12.9022C13.3479 13.3206 14.2363 13.6942 15.1421 14.0217C15.3894 15.076 15.5511 16.1487 15.6255 17.2291C15.3196 17.0013 14.9518 16.8716 14.5706 16.8572C12.8935 16.8572 12.2849 19.5909 12.2849 21.4286C12.2849 23.2075 12.8821 26.5716 15.1421 26.5716C15.3352 26.5704 15.5273 26.5439 15.7135 26.4927V32.8574C15.7159 33.2598 15.8255 33.6543 16.0312 34.0002H2.57049C2.25488 34.0002 1.99906 33.7444 1.99906 33.4287Z" fill="#4B4B63" />
        <path d="M33.9966 33.4286C33.9966 33.7442 33.7407 34 33.4251 34H19.9644C20.17 33.6541 20.2797 33.2596 20.2821 32.8571V26.4925C20.4683 26.5436 20.6604 26.5701 20.8535 26.5714C23.1135 26.5714 23.7106 23.2073 23.7106 21.4284C23.7106 19.5907 23.1021 16.8569 21.4249 16.8569C21.0436 16.8713 20.6755 17.001 20.3695 17.2289C20.444 16.1485 20.6059 15.0758 20.8535 14.0214C21.7595 13.6934 22.6481 13.3192 23.5158 12.9003C23.3752 15.2694 23.5878 18.6844 25.593 20.6895C26.6742 21.7333 28.0561 22.4109 29.5433 22.6267C31.0445 22.9695 32.4622 23.2941 33.9068 25.457C33.967 25.5506 33.9982 25.66 33.9965 25.7713V33.4286H33.9966Z" fill="#4B4B63" />
    </Svg>
}

export default IconThyroid;


