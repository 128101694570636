import { Svg } from "atoms/SvgIcon";

const IconActiveGynaecology = ({ ...rest }) => {
    return <Svg  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <g clip-path="url(#clip0_14195_8821)">
            <path d="M30.5728 21.7788C30.2169 23.278 29.4243 23.9758 27.9225 23.495C27.4704 23.3494 27.0234 23.178 26.5609 23.0766C26.2088 23 25.8256 22.9427 25.4774 23.0025C24.7798 23.1195 24.5615 23.5871 24.8343 24.2264C25.6801 26.2116 25.1865 27.9382 23.4222 29.1737C20.6419 31.1212 15.8297 31.0173 13.1312 28.9463C11.8126 27.9329 11.2448 26.5077 10.9784 24.9305C10.8303 24.0575 10.7342 23.1753 10.5744 22.3034C10.4549 21.6448 10.1873 21.0094 9.49609 20.7912C6.88088 19.9636 6.01168 17.9017 5.62707 15.4632C5.30363 13.4092 5.62834 11.5448 7.34467 10.1976C8.87635 8.99456 10.655 8.4021 12.6194 8.68012C14.98 9.01396 16.8795 11.2018 16.9157 13.5858C16.9249 14.1523 17.0496 14.607 17.403 15.0682C18.2162 16.1283 18.0864 16.9468 17.0782 17.8368C16.6261 18.237 16.1571 18.6267 15.7699 19.084C15.6062 19.2762 15.5257 19.6752 15.6127 19.8986C15.6777 20.0598 16.0896 20.1766 16.3324 20.1558C16.8314 20.1117 17.3212 19.9636 17.9551 19.8272C17.7225 19.5206 17.5432 19.3907 17.5328 19.2464C17.5107 18.9488 17.4834 18.5773 17.6367 18.363C17.7588 18.1941 18.1928 18.0902 18.4058 18.172C19.3075 18.5163 19.8583 19.2335 20.1559 20.1337C20.291 20.5417 20.0949 20.8717 19.7194 21.0392C19.2685 21.2406 18.7931 21.3874 18.3293 21.5628C18.0369 21.6746 17.7499 21.7966 17.3913 21.9408C17.8668 22.5734 18.4463 22.6099 19.0271 22.5657C19.8209 22.5059 20.5329 22.2812 21.1681 21.911C21.5046 21.7161 21.8203 21.4809 22.1152 21.2081C22.2868 21.0484 22.4517 20.8768 22.6089 20.6936C23.4313 19.7361 24.0575 19.5178 25.2372 19.9778C26.0363 20.291 26.7872 20.7365 27.5433 21.1523C28.1591 21.4913 28.7372 21.6941 29.2739 21.034C29.2868 21.0184 29.3051 21.0003 29.3245 20.9951C29.6844 20.8951 30.0455 20.795 30.4055 20.6962C30.4676 21.0591 30.6509 21.45 30.5728 21.7788Z" fill="#24ACDA" />
            <path d="M17.9962 0.526611C8.36145 0.526611 0.523438 8.36463 0.523438 17.9994C0.523438 27.6342 8.36145 35.4736 17.9962 35.4736C27.631 35.4736 35.469 27.6343 35.469 17.9995C35.469 8.36477 27.631 0.526611 17.9962 0.526611ZM17.9962 33.6546C9.36439 33.6546 2.34228 26.6313 2.34228 17.9994C2.34228 9.36756 9.36439 2.3456 17.9962 2.3456C20.0529 2.3456 22.0185 2.74441 23.8192 3.46933C23.8465 4.00975 23.9894 4.51263 24.131 4.99849C24.3024 5.59347 24.4648 6.15738 24.3519 6.65364C24.1895 7.36436 23.5283 7.83981 22.763 8.38938C22.2043 8.7896 21.6262 9.20528 21.1805 9.76652C20.1593 11.0541 20.136 12.7521 20.2957 13.95C20.384 14.6047 20.5335 15.244 20.6777 15.8611C20.8297 16.5081 20.9713 17.1174 21.0492 17.7409C21.1544 18.5985 21.1298 19.4715 20.9765 20.3224C20.9323 20.5706 20.8778 20.8147 20.8115 21.0577L20.8103 21.059C20.7934 21.1227 20.7753 21.1877 20.7557 21.2512C21.2118 20.9913 21.6301 20.6367 22.0173 20.1858C22.1251 20.0611 22.2382 19.9364 22.359 19.8168C22.4356 19.0738 22.4304 18.3228 22.3382 17.5809C22.2524 16.8897 22.0952 16.2167 21.9432 15.5646C21.799 14.9501 21.6625 14.3693 21.5833 13.777C21.4573 12.8286 21.4598 11.5034 22.1978 10.5745C22.5317 10.1549 23.0125 9.80927 23.5204 9.4442C24.3765 8.82967 25.3471 8.1333 25.6185 6.94192C25.807 6.11828 25.5796 5.33219 25.3782 4.63722C25.3276 4.46313 25.2782 4.29156 25.2366 4.12408C30.2333 6.74196 33.6502 11.979 33.6502 17.9994C33.6502 26.6313 26.6281 33.6546 17.9962 33.6546Z" fill="#7FC248" />
        </g>
        <defs>
            <clipPath id="clip0_14195_8821">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </Svg>

}

export default IconActiveGynaecology;

