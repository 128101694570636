import { Svg } from "atoms/SvgIcon";

const IconTopDoctors = ({ ...rest }) => {
    return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <path d="M32.625 2.25H29.8125V1.6875C29.8125 0.757266 29.0552 0 28.125 0C27.1948 0 26.4375 0.757266 26.4375 1.6875V2.25H9.5625V1.6875C9.5625 0.757266 8.80523 0 7.875 0C6.94477 0 6.1875 0.757266 6.1875 1.6875V2.25H3.375C2.13398 2.25 1.125 3.25969 1.125 4.5V28.125C1.125 29.3653 2.13398 30.375 3.375 30.375H11.25C11.5608 30.375 11.8125 30.1233 11.8125 29.8125C11.8125 29.5017 11.5608 29.25 11.25 29.25H3.375C2.75484 29.25 2.25 28.7452 2.25 28.125V9H33.75V28.125C33.75 28.7452 33.2452 29.25 32.625 29.25H26.4375C26.1267 29.25 25.875 29.5017 25.875 29.8125C25.875 30.1233 26.1267 30.375 26.4375 30.375H32.625C33.866 30.375 34.875 29.3653 34.875 28.125C34.875 27.0956 34.875 5.63034 34.875 4.5C34.875 3.25969 33.866 2.25 32.625 2.25ZM27.5625 1.6875C27.5625 1.37742 27.8149 1.125 28.125 1.125C28.4351 1.125 28.6875 1.37742 28.6875 1.6875V4.5C28.6875 4.81008 28.4351 5.0625 28.125 5.0625C27.8149 5.0625 27.5625 4.81008 27.5625 4.5C27.5625 3.52245 27.5625 2.6621 27.5625 1.6875ZM7.3125 1.6875C7.3125 1.37742 7.56492 1.125 7.875 1.125C8.18508 1.125 8.4375 1.37742 8.4375 1.6875V4.5C8.4375 4.81008 8.18508 5.0625 7.875 5.0625C7.56492 5.0625 7.3125 4.81008 7.3125 4.5C7.3125 3.52245 7.3125 2.6621 7.3125 1.6875Z" fill="#4B4B63" />
        <path d="M27.5625 23.625C27.5625 21.7638 26.0487 20.25 24.1875 20.25C22.3263 20.25 20.8125 21.7638 20.8125 23.625C20.8125 25.2949 22.031 26.685 23.625 26.9529V30.0938C23.625 32.7305 21.4805 34.875 18.8438 34.875C16.207 34.875 14.0625 32.7305 14.0625 30.0938V26.968C16.5901 26.6872 18.5625 24.5388 18.5625 21.9375V16.875C18.5625 15.6343 17.5532 14.625 16.3125 14.625C16.0019 14.625 15.75 14.8769 15.75 15.1875C15.75 15.4981 16.0019 15.75 16.3125 15.75C16.9328 15.75 17.4375 16.2547 17.4375 16.875V21.9375C17.4375 24.1087 15.6711 25.875 13.5 25.875C11.3289 25.875 9.5625 24.1087 9.5625 21.9375V16.875C9.5625 16.2547 10.0672 15.75 10.6875 15.75C10.9981 15.75 11.25 15.4981 11.25 15.1875C11.25 14.8769 10.9981 14.625 10.6875 14.625C9.44684 14.625 8.4375 15.6343 8.4375 16.875V21.9375C8.4375 24.5388 10.4099 26.6872 12.9375 26.968V30.0938C12.9375 33.3506 15.5869 36 18.8438 36C22.1006 36 24.75 33.3506 24.75 30.0938V26.9529C26.344 26.685 27.5625 25.2949 27.5625 23.625ZM24.75 24.5981C24.0005 25.0351 23.0625 24.4851 23.0625 23.625C23.0625 23.0048 23.5673 22.5 24.1875 22.5C24.8077 22.5 25.3125 23.0048 25.3125 23.625C25.3125 24.0405 25.0861 24.4041 24.75 24.5981Z" fill="#4B4B63" />
    </Svg>

}

export default IconTopDoctors;