import { Svg } from "atoms/SvgIcon"

const IconPiles = ({ ...rest }) => {
    return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <path d="M17.7754 25.5722H17.458C17.2087 25.5722 17.0045 25.368 17.0045 25.1186V25.0805C17.0045 24.8311 17.2085 24.627 17.458 24.627H23.7746C24.024 24.627 24.2281 24.831 24.2281 25.0805V25.1186C24.2281 25.368 24.024 25.5722 23.7746 25.5722H23.4572L25.0625 35.6569L24.218 35.7913L23.7358 32.7621H17.4969L17.0148 35.7913L16.1703 35.6569L17.7754 25.5722ZM23.5854 31.8168L22.5913 25.5722H18.6413L17.6473 31.8168H23.5854Z" fill="#4B4B63" />
        <path d="M12.7415 11.1864C12.2505 11.1864 11.7991 11.4106 11.5029 11.8016C10.9857 12.484 11.1202 13.4599 11.8026 13.9771C11.8721 14.03 13.5202 15.289 15.0987 16.7892C17.2082 18.7943 17.6985 19.7864 17.812 20.1252C17.4574 20.6179 17.4284 21.2743 17.722 21.7952C17.6279 21.8658 17.5304 21.9313 17.4304 21.9924L12.7363 25.9315L13.412 33.8086C13.5009 34.8456 12.7324 35.7586 11.6952 35.8474C11.6404 35.852 11.5861 35.8544 11.532 35.8544C10.564 35.8544 9.74061 35.1129 9.65632 34.1306L8.89702 25.2782C8.8625 24.876 8.9605 24.4806 9.16389 24.1438C9.13374 24.1461 9.10358 24.148 9.07313 24.148C9.01689 24.148 8.96005 24.1441 8.90275 24.136C8.24312 24.0426 7.78416 23.4323 7.87734 22.7727L9.43529 11.7536C9.45353 11.6249 9.49198 11.5041 9.54596 11.3933C9.57219 11.2735 9.60461 11.1565 9.64878 11.0465C9.78177 11.0374 9.9152 11.0236 10.0486 10.9997C10.2968 10.9554 10.5424 10.8852 10.7785 10.7911C11.3456 10.5651 11.8261 10.2141 12.2031 9.78345C13.0718 9.86079 14.0167 10.2891 14.8283 11.2325C15.7867 12.3597 16.5022 13.341 17.0817 14.3899C15.3966 12.8072 13.7476 11.5541 13.6782 11.5015C13.4061 11.2953 13.0822 11.1864 12.7415 11.1864ZM13.6253 20.265C13.0872 18.6787 12.167 17.1219 11.3032 15.7742L10.2703 23.0805L13.6253 20.265Z" fill="#4B4B63" />
        <path d="M19.0724 22.2382C18.751 22.2382 18.4303 22.1105 18.193 21.8576C17.756 21.3922 17.7598 20.6717 18.1884 20.2112C18.1596 19.9856 17.883 18.958 15.3368 16.538C13.7123 14.9939 12.0281 13.7137 12.0112 13.7009C11.4803 13.2986 11.3762 12.542 11.7784 12.0112C12.1808 11.4801 12.9373 11.376 13.468 11.7781C13.5367 11.8301 15.1692 13.0702 16.844 14.6432C20.114 17.7147 20.5538 19.2361 20.6002 20.1135C20.6378 20.8229 20.3949 21.4447 19.8978 21.9113C19.665 22.1299 19.3684 22.2382 19.0724 22.2382Z" fill="#4B4B63" />
        <path d="M10.6494 10.4689C8.90886 11.1628 6.93568 10.3142 6.24197 8.57366C5.54825 6.83312 6.39651 4.86008 8.1372 4.16622C9.87759 3.47251 11.8509 4.32091 12.5446 6.06145C13.2383 7.80199 12.3896 9.77518 10.6494 10.4689ZM8.00934 9.19696L8.34798 9.4967C9.59564 8.08741 11.4392 8.60366 11.4577 8.60909L11.5842 8.17471C11.4974 8.14953 9.44456 7.57553 8.00934 9.19696Z" fill="#4B4B63" />
        <path d="M26.5727 22.3057L30 25.3144L29.4898 25.9999L26.8298 23.323L26.1368 25.2145L23 21.151L23.1231 20.9999L26.0599 24.0523L26.5727 22.3057Z" fill="#4B4B63" />
        <path d="M27.2512 19.5641L27.3466 20.9999L24 19.4407L24.0385 19.3046L26.9463 20.2841L26.7721 18.9999L30 19.789L29.8585 20.3951L27.2512 19.5641Z" fill="#4B4B63" />
        <path d="M11.1132 3.38442C11.2653 3.49609 11.0374 3.67502 10.8862 3.56355C10.4634 3.25247 10.2788 2.8023 10.4183 2.36069C10.5573 1.92113 11.087 1.70949 10.8006 1.20818C10.6892 1.01315 10.4729 0.83005 10.253 0.705026C10.0863 0.610469 10.2466 0.390379 10.415 0.486159C10.8352 0.724997 11.1526 1.06532 11.2212 1.47483C11.2952 1.91623 10.7443 2.1996 10.6823 2.614C10.6406 2.89309 10.8609 3.19857 11.1132 3.38442Z" fill="#4B4B63" />
        <path d="M9.02228 3.38442C9.17418 3.49609 8.94651 3.67502 8.7953 3.56355C8.37251 3.25247 8.1879 2.8023 8.32739 2.36069C8.4662 1.92113 8.99611 1.70949 8.70971 1.20818C8.59846 1.01315 8.38198 0.83005 8.16207 0.705026C7.99553 0.610469 8.15569 0.390379 8.32412 0.486159C8.74415 0.724997 9.06172 1.06532 9.13043 1.47483C9.20414 1.91623 8.6534 2.1996 8.5914 2.614C8.54972 2.89309 8.77016 3.19857 9.02228 3.38442Z" fill="#4B4B63" />
        <path d="M6.9316 3.38442C7.0835 3.49609 6.85565 3.67502 6.70444 3.56355C6.28181 3.25247 6.09719 2.8023 6.23652 2.36069C6.37533 1.92113 6.90542 1.70949 6.61885 1.20818C6.50759 1.01315 6.29111 0.83005 6.07136 0.705026C5.90465 0.610469 6.06482 0.390379 6.23325 0.486159C6.65346 0.724997 6.97086 1.06532 7.03958 1.47483C7.11346 1.91623 6.56288 2.1996 6.50071 2.614C6.45886 2.89309 6.6793 3.19857 6.9316 3.38442Z" fill="#4B4B63" />
    </Svg>
}

export default IconPiles;


