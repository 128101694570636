import { Svg } from "atoms/SvgIcon";

const IconActiveUrinaryProblems = ({ ...rest }) => {
    return <Svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
        <path d="M17.5818 30.9068L18.6428 36H32.0054V20.9531C32.0054 20.4257 31.5774 19.9982 31.0505 19.9982H26.9457C26.4182 19.9982 25.9907 20.4257 25.9907 20.9531V25.5152C25.9907 26.2911 24.9829 26.5299 24.9829 26.5299H15.9377L15.1025 29.723C16.0928 30.6457 17.5818 30.9068 17.5818 30.9068Z" fill="#7FC248" />
        <path d="M21.2519 13.1639C20.1716 11.5234 18.4758 11.5103 17.3326 12.4758C17.3939 13.1 17.3357 13.5669 17.2217 13.9358C16.484 14.4555 15.749 15.0243 15.6684 15.2794C15.5354 15.7017 15.5523 16.2849 15.7582 16.7568L17.573 14.1464C17.8348 13.7698 18.265 13.5451 18.7237 13.5451C19.0104 13.5451 19.2865 13.6318 19.5226 13.7959C19.8297 14.0093 20.0354 14.3297 20.1016 14.698C20.1678 15.0662 20.0866 15.4381 19.873 15.7453L17.8276 18.6878C18.5864 20.2238 19.0183 21.5789 19.2768 22.705H17.9125H15.035L14.3391 23.706C14.0775 24.0824 13.6471 24.3071 13.1882 24.3071C12.9845 24.3071 12.7868 24.2613 12.6044 24.1771L10.1828 33.4367C9.9491 34.3296 10.4839 35.2429 11.3766 35.4764C11.5186 35.5135 11.6607 35.5311 11.8006 35.5311C12.5423 35.5311 13.2199 35.0336 13.4163 34.2823L15.5701 26.0473H18.9235H20.5711C20.7486 26.0619 20.9455 26.0621 21.1551 26.0473H21.3623C21.5912 26.0473 21.8091 26.0011 22.0077 25.9179C23.8933 25.4872 25.9847 24.0684 24.2163 21.6837C21.7467 18.3535 21.8599 16.6338 21.8277 15.257C21.7505 15.1504 21.6744 15.0377 21.6014 14.9115C21.2963 14.384 21.2226 13.7806 21.2519 13.1639Z" fill="#7FC248" />
        <path d="M22.0656 8.74317C22.0153 8.96966 21.9446 9.18644 21.8577 9.39303C22.5581 10.4453 20.9801 13.1074 21.9184 14.7278C23.1358 16.8305 25.1748 15.9122 24.4655 15.7723C23.7561 15.6324 22.9821 15.0072 23.6751 12.4294C24.3803 9.80638 23.2512 8.71755 22.1468 8.18005C22.134 8.36691 22.1073 8.55488 22.0656 8.74317Z" fill="#7FC248" />
        <path d="M15.0272 7.18179C14.618 9.0268 15.782 10.8543 17.627 11.2637C19.472 11.6729 21.2995 10.5089 21.7087 8.66391C22.1179 6.8189 20.954 4.9914 19.1089 4.58203C17.2639 4.17283 15.4364 5.33678 15.0272 7.18179ZM19.4871 9.38953C19.4281 9.95759 18.6918 9.30661 17.7109 9.20443C16.7296 9.10225 15.8752 9.58753 15.9343 9.01964C15.9935 8.45159 16.8369 8.07406 17.8178 8.17608C18.7989 8.27826 19.5463 8.82164 19.4871 9.38953Z" fill="#7FC248" />
        <path d="M8.0262 18.6462C10.253 18.6462 12.0583 16.8409 12.0583 14.6141C12.0583 12.3873 10.253 10.582 8.0262 10.582C5.79936 10.582 3.99414 12.3873 3.99414 14.6141C3.99414 16.8409 5.79936 18.6462 8.0262 18.6462ZM8.0262 11.2187C9.89843 11.2187 11.4216 12.7419 11.4216 14.6141C11.4216 16.4863 9.89843 18.0095 8.0262 18.0095C6.15398 18.0097 4.63079 16.4863 4.63079 14.6141C4.63079 12.7419 6.15398 11.2187 8.0262 11.2187Z" fill="#24ACDA" />
        <path d="M8.15403 17.1094C10.9293 17.1094 10.6981 13.6403 9.07908 12.9755C9.1948 13.2357 9.31035 14.3343 9.07908 14.3343C8.36811 14.3343 9.07908 12.7154 7.22898 12.0215C7.77825 13.0045 7.46024 14.5655 6.99772 14.5655C6.59519 14.5655 6.564 13.8428 6.53519 13.4092C5.14761 14.3343 5.8414 17.1094 8.15403 17.1094ZM7.41186 14.7873C7.41361 15.0271 7.4074 15.4268 7.62895 15.44C7.88393 15.4549 8.11026 14.6052 7.83969 14.046C8.83604 14.4882 8.39134 15.3572 8.7832 15.3803C8.91053 15.3879 8.88284 14.7785 8.82745 14.6317C9.69791 15.051 9.71191 16.9697 8.18284 16.879C6.90843 16.8032 6.61716 15.2517 7.41186 14.7873Z" fill="#24ACDA" />
        <path d="M11.8689 17.3274L11.6641 17.6308L14.037 19.233L14.246 18.9325L11.8689 17.3274Z" fill="#24ACDA" />
        <path d="M17.6427 21.7565C17.6427 21.958 17.806 22.1213 18.0075 22.1213C18.209 22.1213 18.3723 21.958 18.3723 21.7565C18.3723 21.5551 18.209 21.3917 18.0075 21.3917C17.9738 21.3917 17.9416 21.3978 17.9107 21.4064L16.5684 20.5001L16.3594 20.8008L17.6529 21.6741C17.6467 21.7007 17.6427 21.7281 17.6427 21.7565Z" fill="#24ACDA" />
        <path d="M12.5994 23.7559C12.7794 23.8812 12.9852 23.9412 13.189 23.9412C13.5164 23.9412 13.8383 23.7862 14.0394 23.497L19.5733 15.5363C19.8994 15.0672 19.7835 14.4225 19.3143 14.0963C18.8451 13.7699 18.2005 13.8861 17.8744 14.3553L12.3405 22.316C12.0143 22.785 12.1302 23.4298 12.5994 23.7559Z" fill="#7FC248" />
        <path d="M23.9586 19.7395C23.9743 19.7444 23.9901 19.7467 24.0057 19.7467C24.0736 19.7467 24.1365 19.7027 24.1577 19.6345C24.2694 19.2736 24.2513 18.9561 24.1037 18.6905C23.8758 18.2802 23.422 18.157 23.4028 18.152C23.3178 18.1296 23.2307 18.1805 23.2084 18.2655C23.186 18.3505 23.2369 18.4376 23.3219 18.4598C23.3252 18.4606 23.6654 18.5569 23.8253 18.8449C23.9291 19.0317 23.9387 19.2657 23.8537 19.5402C23.8277 19.6244 23.8747 19.7136 23.9586 19.7395Z" fill="#7FC248" />
        <path d="M24.6491 19.8719C24.6665 19.8781 24.6845 19.881 24.702 19.881C24.7675 19.881 24.829 19.8401 24.8521 19.7747C25.0391 19.244 25.0329 18.7716 24.8336 18.3704C24.5306 17.76 23.8912 17.5491 23.864 17.5405C23.7809 17.5136 23.6907 17.5599 23.6639 17.6435C23.637 17.7272 23.6832 17.8168 23.7669 17.8435C23.7721 17.8453 24.3044 18.0207 24.5484 18.5119C24.7079 18.833 24.709 19.2222 24.5517 19.6686C24.5226 19.7517 24.566 19.8426 24.6491 19.8719Z" fill="#7FC248" />
        <path d="M26.5679 2.77769C26.3577 1.55787 24.0514 2.11033 23.4746 3.41673C25.4425 3.12833 26.7658 3.92573 26.5679 2.77769Z" fill="#24ACDA" />
        <path d="M21.8781 3.02478C22.7857 1.73652 23.9655 1.41071 23.1979 0.898528C22.3822 0.354033 21.442 1.98083 21.8781 3.02478Z" fill="#24ACDA" />
        <path d="M23.2725 5.48837C24.6494 5.54455 25.4479 6.25728 25.4605 5.45017C25.4738 4.59276 23.8329 4.67298 23.2725 5.48837Z" fill="#24ACDA" />
        <path d="M13.4616 0.231729L13.402 2.25946L11.3591 1.25642C11.2693 1.21249 11.161 1.2292 11.0884 1.29875C11.0161 1.36831 10.9949 1.47574 11.0357 1.56758L11.8979 3.51302L9.52572 3.66057C9.42274 3.66693 9.33536 3.73887 9.30942 3.83883C9.28348 3.93878 9.32486 4.04415 9.41176 4.09985L11.4505 5.40642L9.93095 6.40309C9.84803 6.45769 9.80728 6.55812 9.82893 6.65505C9.85057 6.75198 9.93047 6.82535 10.0288 6.8392L11.5617 7.05343L10.3586 8.74405C10.2985 8.82857 10.2997 8.94221 10.3618 9.0253C10.4078 9.08689 10.4793 9.12127 10.5531 9.12127C10.5791 9.12127 10.6052 9.11713 10.6306 9.10838L12.7276 8.38865C12.8522 8.34583 12.9186 8.20991 12.8759 8.08528C12.833 7.9605 12.6974 7.89413 12.5726 7.93694L11.1786 8.41538L12.1786 7.00982C12.2272 6.94186 12.2364 6.85368 12.2035 6.77697C12.1704 6.70041 12.0999 6.64645 12.0173 6.63499L10.7234 6.45418L12.02 5.60378C12.0876 5.55937 12.1282 5.48377 12.1277 5.40292C12.1272 5.32206 12.0858 5.24678 12.0179 5.20317L10.284 4.0919L12.27 3.96839C12.348 3.96361 12.4188 3.9208 12.4592 3.85411C12.4998 3.78726 12.5051 3.70481 12.4734 3.63335L11.7384 1.97472L13.5245 2.8517C13.5974 2.88735 13.6834 2.88369 13.7531 2.84183C13.8226 2.79997 13.8661 2.72565 13.8685 2.64431L13.9184 0.94414L15.2167 2.72803C15.26 2.78756 15.3283 2.82369 15.4018 2.82608C15.473 2.82878 15.5459 2.79695 15.593 2.74045L16.3996 1.77449L16.4958 3.00561C16.5061 3.13708 16.6215 3.2356 16.7523 3.2251C16.8836 3.21491 16.9818 3.09984 16.9717 2.96853L16.83 1.15328C16.8225 1.05651 16.7571 0.973903 16.6646 0.944299C16.5723 0.914854 16.4711 0.944299 16.4089 1.01879L15.4227 2.19945L13.8933 0.0981917C13.8333 0.0155864 13.7273 -0.0194294 13.6297 0.0106523C13.5322 0.040734 13.4645 0.129547 13.4616 0.231729Z" fill="#24ACDA" />
    </Svg>

}


export default IconActiveUrinaryProblems;