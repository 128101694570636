import { Svg } from "atoms/SvgIcon";


const IconEarNoseThroar = ({ ...rest }) => {
    return <Svg  viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} width="100%" height="100%">
        <g clip-path="url(#clip0_14195_6542)">
            <path d="M14.0678 31.3047C14.6391 31.4675 15.2341 31.5529 15.8364 31.5583L16.0597 31.5598C16.6421 31.5658 17.1094 32.0428 17.1035 32.6253C17.0976 33.2041 16.6265 33.6693 16.0492 33.6693C16.0455 33.6693 16.0419 33.6693 16.0382 33.6692L15.8174 33.6677C15.0261 33.6606 14.2431 33.5482 13.4899 33.3335C12.9297 33.1738 12.605 32.5903 12.7647 32.0301C12.9242 31.4699 13.5077 31.145 14.0678 31.3047Z" fill="#4B4B63" />
            <path d="M8.78841 21.2013C9.34943 21.3568 9.67828 21.9378 9.5231 22.4991C9.40357 22.9316 9.21879 23.7986 9.23194 24.2842C9.24776 24.8666 8.78855 25.3514 8.20636 25.3671C8.19659 25.3674 8.18695 25.3675 8.17725 25.3675C7.60807 25.3675 7.13909 24.9139 7.12362 24.3413C7.0976 23.381 7.45043 22.0802 7.49072 21.935C7.64653 21.3741 8.22745 21.0459 8.78841 21.2013Z" fill="#4B4B63" />
            <path d="M8.54479 27.5098C9.06165 27.2416 9.69826 27.4432 9.96657 27.9603C10.1859 28.383 10.4531 28.7846 10.7593 29.152C10.828 29.234 10.8896 29.3045 10.9479 29.3677C11.3429 29.7957 11.3162 30.463 10.8882 30.8582C10.6854 31.0453 10.4289 31.1379 10.1732 31.1379C9.88902 31.1379 9.6058 31.0237 9.39796 30.7985C9.31654 30.7103 9.23286 30.6147 9.14061 30.5046C8.73561 30.0185 8.38363 29.4895 8.09436 28.9319C7.82619 28.4147 8.02785 27.7781 8.54479 27.5098Z" fill="#4B4B63" />
            <path d="M1.0542 14.8363H5.0038L4.62812 14.4229C4.23634 13.9919 4.26812 13.3248 4.69921 12.933C5.13001 12.5411 5.79699 12.5729 6.18885 13.0041L8.16779 15.1816C8.53018 15.5673 8.52983 16.2147 8.16779 16.6005L6.18885 18.7781C5.98072 19.0071 5.69497 19.1235 5.40817 19.1235C5.15511 19.1235 4.90121 19.0329 4.69921 18.8492C4.26819 18.4574 4.23641 17.7903 4.62812 17.3593L5.0038 16.9459H1.0542C0.471806 16.9459 -0.000341415 16.4736 -0.000341415 15.8911C-0.000341415 15.3085 0.471806 14.8363 1.0542 14.8363Z" fill="#4B4B63" />
            <path d="M6.18948 10.1994C5.98136 10.4284 5.69561 10.5447 5.4088 10.5447C5.15575 10.5447 4.90185 10.4541 4.69984 10.2704C4.26883 9.8786 4.23705 9.21155 4.62876 8.78046L5.00444 8.36702H1.05484C0.472444 8.36702 0.000296593 7.8948 0.000296593 7.31226C0.000296593 6.72973 0.472444 6.25751 1.05484 6.25751H5.00444L4.62876 5.84414C4.23698 5.41312 4.26876 4.74607 4.69984 4.35422C5.13065 3.96244 5.79763 3.99415 6.18948 4.4253L8.1685 6.60295C8.35181 6.80433 8.45284 7.07812 8.44152 7.35051C8.43379 7.59773 8.33423 7.83862 8.1685 8.02179L6.18948 10.1994Z" fill="#4B4B63" />
            <path d="M33.483 23.6227C34.1031 21.32 35.5846 16.0417 35.6029 15.9221C36.4916 12.6878 35.8729 9.27557 33.9048 6.5601C32.8895 5.15736 31.5346 3.99791 29.9884 3.20971C28.443 2.4215 26.7105 2.00525 24.9758 2.00525H18.5001C18.1049 2.00525 17.7245 1.86322 17.4285 1.60588L15.8718 0.255176C15.4119 -0.163183 14.6244 -0.0520898 14.2975 0.47666C14.2251 0.587754 12.5601 3.21393 13.6429 7.25893C14.9008 7.38479 16.1123 7.48886 16.5039 7.51487L22.5683 7.91425C24.5828 8.04714 26.193 9.59612 26.4018 11.6007L26.522 12.6005C26.7611 12.5407 27.0072 12.5105 27.2554 12.5105C28.2447 12.5105 29.1967 13.002 29.8668 13.8584C30.4862 14.6508 30.828 15.6893 30.828 16.7834C30.828 17.8775 30.4862 18.9167 29.8668 19.7091C29.2044 20.5557 28.2658 21.045 27.2884 21.0563L27.3756 22.3725C27.4361 23.0743 28.0344 23.6227 28.7411 23.6227H33.483Z" fill="#4B4B63" />
            <path d="M11.1536 20.0593L12.7364 20.6759L12.0079 23.0728C11.6852 24.4721 12.0438 25.9374 12.9494 27.0483C13.8361 28.1339 15.1481 28.7611 16.5501 28.7682L20.1937 28.7871L19.3085 34.7911C19.203 35.4077 19.7268 36.0145 20.3519 35.9997H32.5885C33.1784 36.0117 33.6889 35.471 33.6404 34.8818C33.473 31.672 33.2227 27.9729 33.2016 25.7321H28.741C26.9417 25.7321 25.418 24.3286 25.2725 22.5364C25.2718 22.5314 25.2718 22.5258 25.2711 22.5209L25.0868 19.7379C25.0608 19.3421 25.2591 18.9645 25.6008 18.7613C25.9418 18.5588 26.3686 18.5651 26.704 18.7775C26.8812 18.89 27.0668 18.9469 27.2553 18.9469C28.0484 18.9469 28.7185 17.9562 28.7185 16.7834C28.7185 15.6106 28.0484 14.6199 27.2553 14.6199C26.8854 14.6199 26.5993 14.8378 26.4235 15.0207C26.1415 15.3146 25.7147 15.4193 25.328 15.2907C24.942 15.1613 24.6643 14.821 24.6157 14.416L24.3064 11.8432C24.3057 11.8369 24.305 11.8306 24.3043 11.8243C24.2044 10.8427 23.4162 10.084 22.4297 10.0193L16.3653 9.61996C16.1874 9.608 15.1847 9.52644 14.0084 9.41394C13.8917 10.707 13.9219 12.102 13.9613 12.9739C13.9655 13.0716 13.9353 13.1679 13.8755 13.246L10.5026 17.6517C9.87185 18.4309 10.2143 19.7063 11.1536 20.0593Z" fill="#4B4B63" />
        </g>
        <defs>
            <clipPath id="clip0_14195_6542">
                <rect width="36" height="36" fill="white" transform="matrix(-1 0 0 1 36 0)" />
            </clipPath>
        </defs>
    </Svg>
}


export default IconEarNoseThroar;

